/* Tiny Slider --------------*/
import { tns } from "../../node_modules/tiny-slider/src/tiny-slider";
// import { VenoBox } from 'venobox';
// import VenoBox from '../../node_modules/src/venobox.esm.js';
// import VenoBox from './src/venobox.esm.js'
export default () => {
  //------------------------------------------------------//
  // Slide menu 🎛️ toggle
  //------------------------------------------------------//
  const navigationSlideToggle = document.querySelector(
    "#navigation-slide-toggle-base",
  );
  const navigationSlide = document.querySelector("#navigation-slide");
  const body = document.querySelector("body");
  // On 🐭 click add class to body and toggle 🦻 ARIA lables
  navigationSlideToggle.addEventListener("click", () => {
    body.classList.toggle("showNavigationSlide");
    navigationSlideToggle.setAttribute(
      "aria-expanded",
      navigationSlideToggle.getAttribute("aria-expanded") == "true"
        ? "false"
        : "true",
    );
  });
  // END Slide menu 🎛️ toggle  -------------------------------------//
  //------------------------------------------------------//
  // On browser 📜 scroll full window height
  //------------------------------------------------------//
  window.onscroll = function (ev) {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      // you're at the bottom of the page
      body.classList.add("showScrolled");
    } else {
      body.classList.remove("showScrolled");
    }
  };
  // END On browser 📜 scroll full window height  -------------------------------------//

  document.querySelectorAll(".ACF-slider").forEach(function () {
    var slider = tns({
      container: ".ACF-slider",
      autoplay: true,
      autoplayButton: false,
      controls: false,
      controlsPosition: "bottom",
      controlsText: ["<", ">"],
      nav: true,
      navPosition: "bottom",
      lazyload: false,
      arrowKeys: true,
    });
  });
  /* END Tiny Slider --------------*/

  new VenoBox({
    selector: ".venobox",
    // numeration: true,
    infinigall: true,
    // share: true,
    spinner: "rotating-plane",
  });
};

// if (document.querySelector("#resultHeader") !== null) {
//   const text = `Results for`;
//   const header = document.querySelector("#resultHeader");
//   let searchParams;
//   let obj = {};
//   function getParams() {
//     searchParams = new URLSearchParams(window.location.search);
//     console.warn(searchParams);
//     for (const param of searchParams) {
//       obj[param[0]] = param[1];
//       console.warn(obj);
//     }
//   }
//   window.navigation.addEventListener("navigate", (event) => {
//     searchParams = new URLSearchParams(window.location.search);
//     console.warn(searchParams);
//     for (const param of searchParams) {
//       obj[param[0]] = param[1];
//       console.warn(obj);
//     }
//   });
//   console.warn(obj);
//   header.innerText = `${text}`;
// }
