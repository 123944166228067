export default () => {
  //------------------------------------------------------//
  // 📝 Form labels
  //------------------------------------------------------//
  document
    .querySelectorAll(".gfield input, .gfield textarea")
    .forEach(function (input) {
      // Check if value
      if (input.value) {
        if (
          input.previousElementSibling &&
          input.previousElementSibling.tagName == "LABEL"
        ) {
          input.previousElementSibling.classList.add("selectedInput");
        }
        input
          .closest(".gfield")
          .querySelector(".gfield_label")
          .classList.add("selectedInput");
      }
      // On focus
      input.addEventListener("focus", (event) => {
        if (
          input.previousElementSibling &&
          input.previousElementSibling.tagName == "LABEL" &&
          !input.value
        ) {
          input.previousElementSibling.classList.add("selectedInput");
        }
        input
          .closest(".gfield")
          .querySelector(".gfield_label")
          .classList.add("selectedInput");
      });
      // On focus loss
      input.addEventListener("blur", (event) => {
        if (
          input.previousElementSibling &&
          input.previousElementSibling.tagName == "LABEL" &&
          !input.value
        ) {
          input.previousElementSibling.classList.remove("selectedInput");
        }
        if (!input.value) {
          input
            .closest(".gfield")
            .querySelector(".gfield_label")
            .classList.remove("selectedInput");
        }
      });
    });
  // END 📝 Form labels -------------------------------------//

  //------------------------------------------------------//
  // Sync email field with a dummy username field which is the email field stripped of all weird characters
  //------------------------------------------------------//
  const emailField = document.querySelector(".alloy-gf-send-username input");
  const usernameField = document.querySelector(
    ".alloy-gf-receive-username input",
  );
  if (emailField) {
    emailField.addEventListener("input", updateValue);

    function updateValue(e) {
      let result = emailField.value;
      result = result.replace(
        /[`~!@#$%^&*()–_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
        "",
      );
      usernameField.value = result;
    }
  }
  //------------------------------------------------------//
  // END Sync email field with a dummy username field which is the email field stripped of all weird characters
  //------------------------------------------------------//
};
